import { action, observable } from 'mobx';
import * as mobx from 'mobx';
import { db, auth, fbauth, storage, rtdb } from '../firebase/firebase';

class ObservableStore {	

	//--------------------------------------------------------
	//-----------------------  USERS -------------------------
	//--------------------------------------------------------
	
	@observable uid = '';	
	@observable mail = '';		
	@observable userInfo = {};	
	@observable newUser = false;	
	@observable eventIndex = [];	
	
	@action setFirstOrganisatie(naam){
		this.userInfo = {bedrijfsnaam: naam}; 
		db.collection("organisaties").doc(this.uid).set(this.userInfo);
	}
	
	@action setLoginInfo(uid, mail){
		this.mail = mail;
		this.uid = uid;
		db.collection("organisaties").doc(uid).get().then(doc => {
			if (doc.exists) {					
				this.userInfo = doc.data();				
				if(doc.data().eventindex){
					var eventIndexObj = doc.data().eventindex;					
					var eventIndexArr = Object.values(eventIndexObj);
					this.eventIndex = eventIndexArr;
				}
				this.getEvent();
			}
			else{this.newUser = true}
		})	
	}

	@action setOrganisatieInfo(){	
		db.collection("organisaties").doc(this.uid).update(this.userInfo);		
	}
	
	@action updateAcc = (mode, oldpass, newpass, newmail) => {	
		var reauthenticate = (currentPassword) => {
			var cred = fbauth.EmailAuthProvider.credential(auth.currentUser.email, currentPassword);
			return auth.currentUser.reauthenticateWithCredential(cred);
		}		
		if(mode === 'mail'){
			reauthenticate(oldpass).then(() => {
				auth.currentUser.updateEmail(newmail).then(() => {alert('Account info is aangepast')});	
			}).catch(() => {alert('Je huidige wachtwoord is incorrect')});	
		}
		if(mode === 'pass'){
			reauthenticate(oldpass).then(() => {
				auth.currentUser.updatePassword(newpass).then(() => {alert('Account info is aangepast')});	
			}).catch(() => {alert('Je huidige wachtwoord is incorrect')});	
		}
	}
	
	//--------------------------------------------------------
	//-----------------------  ADMIN -------------------------
	//--------------------------------------------------------
	
	@observable isAdmin = false;	
	@observable organisorList = [];
	
	@action setAdmin() {
		this.isAdmin = true;
		this.getOrganisorList();
	}
	
	@action getOrganisorList() {
		db.collection("organisaties").get().then(querySnapshot => {										
			querySnapshot.forEach(doc => {
				var orgnisatieObject = doc.data();
				orgnisatieObject['oid'] = doc.id;
				this.organisorList.push(orgnisatieObject);
			});
		})		
	}
	
	@action setOrganisor(key) {
		this.uid = this.organisorList[key].oid;
		this.userInfo = this.organisorList[key];
		this.eventIndex = Object.values(this.organisorList[key].eventindex);				
		this.getEvent();
	}	
	
	//--------------------------------------------------------
	//-----------------------  EVENTS ------------------------
	//--------------------------------------------------------		

	@observable currEvent = {};
	
	@action getEvent(){
		var lastEvent = localStorage.getItem('lastevent');
		if(lastEvent && this.eventIndex.find(evt => evt.eid === lastEvent)){this.setCurrEvent(lastEvent)}
		else{this.setCurrEvent(this.eventIndex[this.eventIndex.length - 1].eid)}
	}
	
	@action setCurrEvent(eid){
		this.currEvent['ID'] = eid;
		if (typeof(this.EventListener) !== 'undefined'){this.EventListener()}	
		this.EventListener = db.collection("organisaties").doc(this.uid).collection("events").doc(eid).onSnapshot(doc => {if(doc.exists){this.currEvent = doc.data()}});
		localStorage.setItem('lastevent', eid);
	}
	
	@action saveEventInfo(type){
		if(type){this.currEvent.publishNotf[type] = true}
		db.collection("organisaties").doc(this.uid).collection("events").doc(this.currEvent.ID).update(this.currEvent);			
		this.updateEventIndex();
	}
	
	@action updateEventIndex(){
		var changed = false;
		var newEventIndex = this.eventIndex.slice();
		newEventIndex.forEach((item, index) => {
			if(item.eid === this.currEvent.ID){
				if(item.naam !== this.currEvent.naam){newEventIndex[index].naam = this.currEvent.naam; changed = true}
				if(item.tijd !== this.currEvent.tijd){newEventIndex[index].tijd = this.currEvent.tijd; changed = true}
			}
		});
		if(changed){db.collection("organisaties").doc(this.uid).update({eventindex: newEventIndex})}
	}

	@action dublicateEvent(eventID, eventName){
		var eventref = db.collection("organisaties").doc(this.uid).collection("events").doc();
		db.collection("organisaties").doc(this.uid).collection("events").doc(eventID).onSnapshot(doc => {
			if(doc.exists){
				this.currEvent = doc.data();
				this.currEvent.ID = eventref.id;
				this.currEvent.publish = false;
				this.currEvent.naam = eventName;
				this.currEvent.type = 'event';
				this.currEvent.announcements = [];
				this.currEvent.pushmessages = [];
				this.currEvent.ticketshops = [];
				this.currEvent.kluizen = [];
				this.currEvent.facturen = [];
				this.currEvent.notifications = [];
				this.currEvent.timetable = false;
				this.currEvent.publishNotf = {menu: true, settings: true, afhaal: true, oplaad: true, lockers: true}
				this.currEvent.tijd = Math.round(new Date().getTime() / 1000);
				eventref.set(this.currEvent);
				this.setEventIndex();
		}});
	}
	
	@action createEvent(eventName) {	
		var eventref = db.collection("organisaties").doc(this.uid).collection("events").doc();
		this.currEvent = {
			ID: eventref.id, 
			oid: this.uid,
			naam: eventName ,
			type: 'event', 
			actief: true,
			hardcups: {actief: false, gratis: false, return: false, kosten: 0.5},
			cover: '', 
			locatie: '', 
			capaciteit: 0, 
			tijd: Math.round(new Date().getTime() / 1000), 
			dagen: 1,
			tokenverkoop: false, 
			muntprijs: '3,30', 
			menus: [{label: 'Standaard menu', menu: [{group: '1', id: 1, name: 'Meest gekozen', status: true, items: []}]}], 
			barren: [], 
			muntretour: {allowed: false, verval: '', periode: 14, date: 0}, 
			publish: false, 
			oplaadpunten: [],
			announcements: [],
			pushmessages: [],
			kluizen: [],
			ticketshops: [],
			krediet: true,
			clearing: false,
			review: true,
			pay: {accept: false, serviceId: '', merchantId: '', lockerFee: 0.30, serviceFee: {start: 0.10, percentage: 2, chargeUser: true}},
			tapp: {enable: false, locationKey: ''},
			publishNotf: {menu: false, settings: false, afhaal: false, oplaad: false, lockers: false},
			paymentMethods:[
				{"id": "10", "name": "Ideal", "status": false, "base": 0.12, "per": 0.00, "retour": true, "retourfee": 0.25, "allowCollect": true, "collectMethod": "ppcollect1", "img": "local:ideal"},
				{"id": "436", "name": "Bancontact", "status": false, "base": 0.12, "per": 0.00, "retour": true, "retourfee": 0.25, "allowCollect": true, "collectMethod": "ppcollect1", "img": "local:bancontact"},
				{"id": "pp1", "name": "Pin / Card", "status": false, "base": 0.00, "per": 0.00, "retour": false, "retourfee": 0.00, "allowCollect": false, "collectMethod": "", "img": "local:pinnen"},
				{"id": "pp2", "name": "Cash", "status": false, "base": 0.00, "per": 0.00, "retour": false, "retourfee": 0.00, "allowCollect": false, "collectMethod": "", "img": "local:cash"},
				{"id": "559", "name": "Sofort", "status": false, "base": 0.06, "per": 1.21, "retour": true, "retourfee": 0.25, "allowCollect": true, "collectMethod": "ppcollect1", "img": "local:sofort"},
				{"id": "2379", "name": "Payconic", "status": false, "base": 0.18, "per": 0.00, "retour": true, "retourfee": 0.25, "allowCollect": true, "collectMethod": "ppcollect1", "img": "https://firebasestorage.googleapis.com/v0/b/partypay-a3d12.appspot.com/o/paymentIcons%2Fpayconiq3.svg?alt=media&token=205fdb2a-22b7-4f0a-bdc1-faf24546c9a0"},
				{"id": "694", "name": "Giropay", "status": false, "base": 0.06, "per": 1.51, "retour": true, "retourfee": 0.25, "allowCollect": true, "collectMethod": "ppcollect1", "img": "https://firebasestorage.googleapis.com/v0/b/partypay-a3d12.appspot.com/o/paymentIcons%2Fgiropay3.svg?alt=media&token=c22edf87-d0a3-4773-9477-5044f3ac2362"},
				{"id": "2970", "name": "Pay by Bank", "status": false, "base": 0.06, "per": 0.30, "retour": true, "retourfee": 0.25, "allowCollect": true, "collectMethod": "ppcollect1", "img": "https://firebasestorage.googleapis.com/v0/b/partypay-a3d12.appspot.com/o/paymentIcons%2Fpaybybank.svg?alt=media&token=eb3815a3-471c-40d9-bbf9-5430fe13dc24"},
				{"id": "2268", "name": "Carte bancaire", "status": false, "base": 0.22, "per": 2.12, "retour": true, "retourfee": 0.25, "allowCollect": false, "collectMethod": "", "img": "local:cartebancaire"},
				{"id": "2511", "name": "Festival Cadeaukaart", "status": false, "base": 0.30, "per": 0.00, "retour": false, "retourfee": 0.00, "allowCollect": false, "collectMethod": "", "img": "local:festivalgiftcard"},
				{"id": "138", "name": "PayPal", "status": false, "base": 0.12, "per": 0.00, "retour": true, "retourfee": 0.25, "allowCollect": false, "collectMethod": "", "img": "local:paypal"},
				{"id": "706", "name": "Visa Mastercard", "status": false, "base": 0.22, "per": 2.12, "retour": true, "retourfee": 2.50, "allowCollect": true, "collectMethod": "ppcollect2", "img": "local:mastercard"},
				{"id": "2277", "name": "Apple Pay", "status": false, "base": 0.22, "per": 2.12, "retour": true, "retourfee": 0.25, "allowCollect": false, "collectMethod": "", "img": "local:applepay"},
				{"id": "2558", "name": "Google Wallet", "status": false, "base": 0.22, "per": 2.12, "retour": true, "retourfee": 0.25, "allowCollect": false, "collectMethod": "", "img": "https://firebasestorage.googleapis.com/v0/b/partypay-a3d12.appspot.com/o/paymentIcons%2Fgooglewallet.svg?alt=media&token=f2965f82-fec5-460d-b399-25c906006209"},
				{"id": "ppcollect1", "hide": true, "name": "Sepa Incasso", "status": true, "base": 0.30, "per": 0.00, "retour": false, "retourfee": 0.00, "allowCollect": false, "collectMethod": "", "img": "local:sepa"},
				{"id": "ppcollect2", "hide": true, "name": "Card Collect", "status": true, "base": 0.18, "per": 2.42, "retour": false, "retourfee": 0.00, "allowCollect": false, "collectMethod": "", "img": "local:mastercard"}
			],
			idealBanks:[
				{id: "4", name: "ING", img: 'local:INGBNL2A'},
				{id: "5", name: "SNS", img: 'local:SNSBNL2A'},
				{id: "1", name: "ABN AMRO", img: 'local:ABNANL2A'},
				{id: "2", name: "Rabobank", img: 'local:RABONL2U'},
				{id: "8", name: "ASN Bank", img: 'local:ASNBNL21'},
				{id: "12", name: "Knab", img: 'local:KNABNL2H'},
				{id: "5080", name: "Bunq", img: 'local:BUNQNL2A'},
				{id: "5084", name: "Revolut", img: 'local:REVOLUT'},
				{id: "9", name: "RegioBank", img: 'local:RBRBNL21'},
				{id: "10", name: "Triodos Bank", img: 'local:TRIONL2U'},
				{id: "11", name: "van Lanschot", img: 'local:FVLBNL22'}
			]
		};
		eventref.set(this.currEvent);
		this.setEventIndex();
	}

	@action setEventIndex(){
		db.collection("organisaties").doc(this.uid).get().then(doc => {
			var eventIndex = [];
			if(doc.exists && doc.data().eventindex) {eventIndex = doc.data().eventindex;}
			var eventIndexArr = Object.values(eventIndex);
			eventIndexArr.push({eid: this.currEvent.ID, naam: this.currEvent.naam, tijd: this.currEvent.tijd});			
			db.collection("organisaties").doc(this.uid).update({eventindex: eventIndexArr})			
			this.eventIndex = eventIndexArr;
			if(this.newUser === true){this.newUser = false}
		})
	}

	@action deleteEvent(eid){
		db.collection("organisaties").doc(this.uid).get().then(doc => {	
			this.eventIndex = doc.data().eventindex.filter((obj) => obj.eid !== eid);
			db.collection("organisaties").doc(this.uid).update({eventindex: this.eventIndex});			
			db.collection("organisaties").doc(this.uid).collection("events").doc(eid).delete();
			db.collection("events").doc(eid).delete();
			alert('Evenement is verwijderd');
			this.setCurrEvent(this.eventIndex[0].eid);
		})
	}
	
	@observable currMenu = 0;	
	@observable currMenuGroep = 0;	
	
	//--------------------------------------------------------
	//--------------------- PUBLISH EVENT --------------------
	//--------------------------------------------------------		
	
	@observable publishStatus = '';		
	
	@action publishEventInfo(details, menu, afhaalpunten, oplaadpunten, lockers, menuInd){		
		this.publishStatus = 'await';
		var firstPublish = this.currEvent.publish ? false : true;
		var newEventArr = {}
		if(details || firstPublish){
			newEventArr['actief'] = this.currEvent.actief;
			newEventArr['hardcups'] = this.currEvent.hardcups;
			newEventArr['type'] = this.currEvent.type;
			newEventArr['naam'] = this.currEvent.naam;
			newEventArr['cover'] = this.currEvent.cover;
			newEventArr['oid'] = this.uid;
			newEventArr['tijd'] = this.currEvent.tijd;
			newEventArr['dagen'] = this.currEvent.dagen;
			newEventArr['locatie'] = this.currEvent.locatie;
			newEventArr['tokenverkoop'] = this.currEvent.tokenverkoop;
			newEventArr['muntprijs'] = this.currEvent.muntprijs.replace(",", ".");
			newEventArr['muntretour'] = this.currEvent.muntretour;
			newEventArr['supportnum'] = '+31854011114';	
			newEventArr['minversie'] = '4.2.7';	
			newEventArr['krediet'] = this.currEvent.krediet;	
			newEventArr['pay'] = this.currEvent.pay;
			newEventArr['review'] = this.currEvent.review;
			newEventArr['clearing'] = this.currEvent.clearing;
			newEventArr['ticketshops'] = this.currEvent.ticketshops;
			newEventArr['paymentMethods'] = this.currEvent.paymentMethods;
			newEventArr['idealBanks'] = this.currEvent.idealBanks;
			if(this.currEvent.muntmin){newEventArr['muntmin'] = this.currEvent.muntmin}
			if(this.currEvent.ticketids){newEventArr['ticketids'] = this.currEvent.ticketids}
			if(this.currEvent.muntaantal){newEventArr['muntaantal'] = this.currEvent.muntaantal}
			if(this.currEvent.festivalmap){newEventArr['festivalmap'] = this.currEvent.festivalmap}
			if(!this.currEvent.festivalmap){newEventArr['festivalmap'] = ''}
			if(this.currEvent.timetable){newEventArr['timetable'] = this.currEvent.timetable}
			if(typeof this.currEvent.wristband !== "undefined"){newEventArr['wristband'] = this.currEvent.wristband}
			if(this.currEvent.headphones){newEventArr['headphones'] = this.currEvent.headphones}
			if(!this.currEvent.preventShare){newEventArr['preventShare'] = true}
			this.currEvent.publishNotf.settings = false;
		}
		if(menu || firstPublish){
			if(menuInd === 'off'){newEventArr['menu'] = []}
			else{newEventArr['menu'] = this.currEvent.menus[menuInd].menu}
			this.currEvent.publishNotf.menu = false;
		}
		if(lockers || firstPublish){
			newEventArr['kluizen'] = this.currEvent.kluizen;
			this.currEvent.publishNotf.lockers = false;
		}
		if(afhaalpunten || firstPublish){
			newEventArr['barren'] = this.currEvent.barren;
			this.currEvent.publishNotf.afhaal = false;
		}
		if(oplaadpunten || firstPublish){
			newEventArr['oplaadpunten'] = this.currEvent.oplaadpunten;
			this.currEvent.publishNotf.oplaad = false;
		}
		if(firstPublish){
			newEventArr['announcements'] = [];
			this.currEvent.publish = true; 
			db.collection("organisaties").doc(this.uid).collection("events").doc(this.currEvent.ID).update(this.currEvent)
			db.collection("events").doc(this.currEvent.ID).set(newEventArr).then(() => {this.publishStatus = 'finished'; setTimeout(() => {this.publishStatus = ''}, 10000);});
			this.setScanners();
		}
		else{
			db.collection("events").doc(this.currEvent.ID).update(newEventArr).then(() => {this.publishStatus = 'finished'; setTimeout(() => {this.publishStatus = ''}, 10000);});
		}
		this.saveEventInfo();
	}

	@action setScanners(){
		if(this.uid === "rbmVdOEC9zQURdMwujt59lYfmIC3"){
			var scanMail = "scanner@boot10.nl";
			var scanID = "iXP3l6NIxCNeocJzCoHF2HnjS0a2";
			var opwaardeerMail = "opwaardeer@docks.nl";
			var opwaardeerID = "VjjhzFQViYWw7vRmCUkNSUx3naJ2";
		}
		else{
			var scanMail = "scanner@partypay.nl";
			var scanID = "oHfpUOzRiRewcOxpN6GKEmDA6pr2";
			var opwaardeerMail = "opwaardeer@partypay.nl";
			var opwaardeerID = "gbTriLdrwgOjEXYrwq4P4jF6MDD2";
		}
		var scannerObj = {active: true, mail: scanMail, barid: 'all', manual: false, name: 'partypay scanner', function: 'afhaal'};
		var scannerObj2 = {active: true, mail: opwaardeerMail, barid: 'all', manual: false, name: 'partypay opwaardeer', function: 'oplaad'};
		db.collection("events").doc(this.currEvent.ID).collection("users").doc(scanID).set({scanner: scannerObj},{merge: true});
		db.collection("events").doc(this.currEvent.ID).collection("users").doc(opwaardeerID).set({scanner: scannerObj2},{merge: true});
		rtdb.ref('/scanners/' + this.uid + '/' + this.currEvent.ID).update({[scanID]:true})
		rtdb.ref('/scanners/' + this.uid + '/' + this.currEvent.ID).update({[opwaardeerID]:true})
	}

	//--------------------------------------------------------
	//---------------------  CLEAR DATA ----------------------
	//--------------------------------------------------------	
	
	@action clearData() {
		this.uid = '';	
		this.eventIndex = [];
		this.currEvent.ID = '';
		this.email = '';	
		this.bedrijfsnaam = '';
		this.adres = '';
		this.postcodeplaats = '';
		this.contactpersoon = '';
		this.contactnum = '';
		this.currEvent = {};
	}
	
}

const observableStore = new ObservableStore();
export default observableStore;
